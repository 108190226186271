/** site color palette **/
$body-bg: #FFFFFF;
$body-color: #222222;

$color-hilite: #E6AB05;
$color-white: #FFFFFF;

$color-primary: #CC7722;
$color-primary-text: #534009;
$color-primary-hover: darken($color-primary, 10%);
$color-primary-text-hover: darken(#FFFFFF, 10%);
$color-primary-active: darken($color-primary, 15%);
$color-primary-text-active: darken($color-primary-text-hover, 10%);

$color-secondary: #F2D682;
$color-secondary-text: #7C88A3;

$color-disabled: #CCCCCC;

/** responsive util **/
$screen-sm-min: 568px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1280px;

/** typography **/
$font-family-sans-serif: "Source Sans Pro", Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;

$font-size-h1: 2em;
$font-size-h2: 1.75em;
$font-size-h3: 1.5em;
$font-size-h4: 1.2em;
