@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(https://fonts.googleapis.com/css?family=Satisfy);
/** site color palette **/
/** responsive util **/
/** typography **/
html {
  font-size: 100.01%;
  box-sizing: border-box; }

html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  color: #222222; }

*, *:before, *:after {
  box-sizing: inherit; }

:focus {
  outline: none; }

html, button, input, select, textarea,
.pure-g [class*='pure-u'] {
  font-family: "Source Sans Pro", Arial, sans-serif; }

abbr,
abbr[title],
acronym,
acronym[title] {
  border-bottom: 1px dotted #7C88A3;
  cursor: help;
  text-decoration: none; }

p {
  margin: 0;
  padding: 0; }

ul {
  list-style: circle; }

ol {
  list-style: lower-roman; }

/*********
** shared classes
*********/
.continuous-ol {
  counter-reset: rule; }
  .continuous-ol ol {
    list-style: none;
    margin-top: 0;
    margin-bottom: 10px; }
    .continuous-ol ol > li::before {
      counter-increment: rule;
      content: counter(rule) ". ";
      float: left;
      margin-left: -30px;
      width: 20px;
      text-align: right; }

.disabled {
  color: #CCCCCC !important; }

.float-off {
  float: none !important; }

.list-no-bullets {
  list-style: none; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.page-container .header {
  background-color: #214850;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 2em; }
  .page-container .header > .title {
    font-size: 2.75em;
    font-weight: bold; }
  .page-container .header > .subtitle {
    font-size: 1.5em; }

.page-container .nav-header {
  background: url("/images/navheader.png") no-repeat center;
  background-size: cover;
  border-right: 1px solid #000000;
  height: 140px; }
  @media (max-width: 768px) {
    .page-container .nav-header {
      align-items: center;
      background: none;
      display: flex;
      height: 40px; } }
  .page-container .nav-header .mobile-menu-toggle {
    display: none;
    margin: 1rem; }
    @media (max-width: 768px) {
      .page-container .nav-header .mobile-menu-toggle {
        display: block; } }

.page-container nav {
  border-right: 1px solid #000000;
  padding-left: 1em; }
  .page-container nav > .menu {
    padding-bottom: 4em; }
    .page-container nav > .menu ul li {
      border-bottom: 1px solid transparent;
      margin: 0.5em 0; }
      .page-container nav > .menu ul li.active, .page-container nav > .menu ul li:hover:not(.active) {
        border-bottom-color: #000000; }
      .page-container nav > .menu ul li.active {
        font-size: 1.2em;
        font-weight: bold; }
      .page-container nav > .menu ul li > a {
        color: #222222;
        display: block;
        text-decoration: none; }
  @media (max-width: 768px) {
    .page-container nav {
      background: #E6E6E6;
      border-right: none;
      padding: 0 1em; }
      .page-container nav > .menu:not(.toggled) {
        display: none; }
      .page-container nav > .menu {
        padding-bottom: 0; } }

.page-container .nav-toggle {
  border-radius: 5px;
  color: #E6AB05;
  cursor: pointer;
  display: none;
  padding: 0.25em; }
  .page-container .nav-toggle:hover {
    color: #CC7722; }
  @media (max-width: 768px) {
    .page-container .nav-toggle {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0; } }

.page-container .page-content {
  padding: 1em 2em; }
  @media (max-width: 768px) {
    .page-container .page-content {
      padding: 0; } }

.spinner .ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.spinner .ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #CC7722;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.spinner .ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: ellipsis1 0.6s infinite;
          animation: ellipsis1 0.6s infinite; }

.spinner .ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: ellipsis2 0.6s infinite;
          animation: ellipsis2 0.6s infinite; }

.spinner .ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: ellipsis2 0.6s infinite;
          animation: ellipsis2 0.6s infinite; }

.spinner .ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: ellipsis3 0.6s infinite;
          animation: ellipsis3 0.6s infinite; }

@-webkit-keyframes ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

.bordered-image {
  border: 1px solid #000000;
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.25); }

.page-highlight-image {
  float: right;
  margin: 1rem;
  max-width: 380px; }

.home .cartoon-introduction {
  background-color: #214850;
  background-color: rgba(33, 72, 80, 0.75);
  color: #FFFFFF;
  display: flex;
  margin: -1em -2em;
  padding: 1em; }
  @media screen and (max-width: 768px) {
    .home .cartoon-introduction {
      align-items: center;
      flex-direction: column-reverse;
      margin: 0; } }
  .home .cartoon-introduction .motto-stack {
    display: flex;
    flex: 1 1;
    flex-direction: column; }
    .home .cartoon-introduction .motto-stack > h3 {
      margin: .5rem 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
  .home .cartoon-introduction .cartoon {
    margin-left: 2rem;
    max-height: 191px; }

.home .welcome-text {
  padding-top: 2em; }
  @media screen and (max-width: 768px) {
    .home .welcome-text {
      padding: 2em; } }

.home .quote-container {
  display: flex;
  justify-content: center; }
  .home .quote-container .quote {
    background-color: #FDF6E3;
    border: 1px solid #BFBAAC;
    border-radius: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    color: #839496;
    display: inline-flex;
    flex-direction: column;
    font-family: 'Satisfy', cursive;
    font-size: 2em;
    margin: 2rem 0;
    padding: 1em; }
    .home .quote-container .quote > p:first-child::before, .home .quote-container .quote > p:first-child::after {
      content: '"'; }
    .home .quote-container .quote > p:last-child {
      text-align: right; }

.info .dinner-dates-container {
  display: flex; }
  .info .dinner-dates-container > table {
    margin-right: 1rem; }
    .info .dinner-dates-container > table.previous-year {
      opacity: .5; }

.directory table > thead > tr > td {
  background-color: #67464A;
  color: #FFFFFF; }

.directory table > tbody > tr:nth-child(odd) {
  background-color: #E7E6E6; }

.directory table > tbody td {
  vertical-align: top; }

.directory .person-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column; }
  .directory .person-wrapper > .name {
    font-weight: bold; }
  .directory .person-wrapper > img {
    height: 125px;
    margin: .5em 0; }
  .directory .person-wrapper > .shortbio {
    font-size: .8em;
    font-style: italic; }

.directory .contact-info,
.directory .allergies,
.directory .dislikes,
.directory .favorites {
  margin-bottom: 2em; }
  .directory .contact-info > .name,
  .directory .allergies > .name,
  .directory .dislikes > .name,
  .directory .favorites > .name {
    text-decoration: underline; }

.directory .allergies > p:not(.name) {
  color: red;
  list-style: none;
  margin: 0;
  padding: 0; }

.bylaws p {
  margin-top: 1rem; }

.faq .example-schedule {
  display: flex;
  justify-content: center;
  margin: 1rem 0; }
  .faq .example-schedule table > thead > tr {
    background-color: #203864;
    color: #FFFFFF; }
  .faq .example-schedule table > thead td,
  .faq .example-schedule table > tbody td {
    min-width: 125px;
    width: 125px; }
  .faq .example-schedule table > tbody td.host {
    color: red; }

.dinner table {
  margin-top: 1rem;
  width: 100%; }
  .dinner table tr.current {
    font-weight: bold; }
  .dinner table tbody > tr:not(.spacer) {
    background-color: #E4C5D1; }
    .dinner table tbody > tr:not(.spacer).secondary {
      background-color: #AEB2B3; }
    .dinner table tbody > tr:not(.spacer).tertiary {
      background-color: #859B9F; }
    .dinner table tbody > tr:not(.spacer).quaternary {
      background-color: #B3A2A4; }
    .dinner table tbody > tr:not(.spacer):hover {
      background-color: #E1DFDC; }
  .dinner table tbody td {
    padding: .25em 1em; }
    .dinner table tbody td.spacer {
      padding: .5em 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

