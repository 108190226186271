.directory {
  table {
    > thead > tr > td {
      background-color: #67464A;
      color: #FFFFFF;
    }

    > tbody {
      > tr:nth-child(odd) {
        background-color: #E7E6E6;
      }

      td {
        vertical-align: top;
      }
    }
  }

  .person-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;

    > .name {
      font-weight: bold;
    }

    > img {
      height: 125px;
      margin: .5em 0;
    }

    > .shortbio {
      font-size: .8em;
      font-style: italic;
    }
  }

  .contact-info,
  .allergies,
  .dislikes,
  .favorites {
    margin-bottom: 2em;

    > .name {
      text-decoration: underline;
    }
  }

  .allergies {
    > p:not(.name) {
      color: red;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
