html {
  font-size: 100.01%;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: $body-color;
}

*, *:before, *:after {
  box-sizing: inherit;
}

:focus {
  outline: none;
}

html, button, input, select, textarea,
.pure-g [class *= 'pure-u'] {
  font-family: $font-family-base;
}

abbr,
abbr[title],
acronym,
acronym[title] {
  border-bottom: 1px dotted $color-secondary-text;
  cursor: help;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: circle;
}

ol {
  list-style: lower-roman;
}

/*********
** shared classes
*********/
.continuous-ol {
  counter-reset: rule;

  ol {
    list-style: none;
    margin-top: 0;
    margin-bottom: 10px;

    > li {
      &::before {
        counter-increment: rule;
        content: counter(rule) '. ';
        float: left;
        margin-left: -30px;
        width: 20px;
        text-align: right;
      }
    }
  }
}

.disabled {
  color: $color-disabled !important;
}

.float-off {
  float: none !important;
}

.list-no-bullets {
  list-style: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
