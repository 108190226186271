.faq {
  .example-schedule {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    table {
      > thead > tr {
        background-color: #203864;
        color: #FFFFFF;
      }

      > thead td,
      > tbody td {
        min-width: 125px;
        width: 125px;
      }

      > tbody td.host {
        color: red;
      }
    }
  }
}
