.dinner {
  table {
    margin-top: 1rem;
    width: 100%;

    tr {
      &.current {
        font-weight: bold;
      }
    }

    tbody {
      > tr:not(.spacer) {
        background-color: #E4C5D1;

        &.secondary {
          background-color: #AEB2B3;
        }

        &.tertiary {
          background-color: #859B9F;
        }

        &.quaternary {
          background-color: #B3A2A4;
        }

        &:hover {
          background-color: #E1DFDC;
        }
      }

      td {
        padding: .25em 1em;

        &.spacer {
          padding: .5em 0;
        }
      }
    }
  }
}
