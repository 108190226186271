@import url('https://fonts.googleapis.com/css?family=Satisfy');

.home {
  .cartoon-introduction {
    background-color: #214850;
    background-color: rgba(33, 72, 80, .75);
    color: #FFFFFF;
    display: flex;
    margin: -1em -2em;
    padding: 1em;

    @media screen and (max-width: $screen-md-min) {
      align-items: center;
      flex-direction: column-reverse;
      margin: 0;
    }

    .motto-stack {
      display: flex;
      flex: 1;
      flex-direction: column;

      > h3 {
        margin: .5rem 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
      }
    }

    .cartoon {
      margin-left: 2rem;
      max-height: 191px;
    }
  }

  .welcome-text {
    padding-top: 2em;

    @media screen and (max-width: $screen-md-min) {
      padding: 2em;
    }
  }

  .quote-container {
    display: flex;
    justify-content: center;

    .quote {
      background-color: #FDF6E3;
      border: 1px solid #BFBAAC;
      border-radius: 8px;
      @include box-shadow(2px 2px 8px rgba(0, 0, 0, .25));
      color: #839496;
      display: inline-flex;
      flex-direction: column;
      font-family: 'Satisfy', cursive;
      font-size: 2em;
      margin: 2rem 0;
      padding: 1em;

      > p:first-child {
        &::before, &::after {
          content: '"';
        }
      }

      > p:last-child {
        text-align: right;
      }
    }
  }
}
