@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import 'styles/elements';
@import 'styles/variables';
@import 'styles/globals';

$page-indent: 2em;

.page-container {
  .header {
    background-color: #214850;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ($page-indent / 2) $page-indent;

    > .title {
      font-size: 2.75em;
      font-weight: bold;
    }

    > .subtitle {
      font-size: 1.5em;
    }
  }

  .nav-header {
    background: url('/images/navheader.png') no-repeat center;
    background-size: cover;
    border-right: 1px solid #000000;
    height: 140px;

    @media (max-width: $screen-md-min) {
      align-items: center;
      background: none;
      display: flex;
      height: 40px;
    }

    .mobile-menu-toggle {
      display: none;
      margin: 1rem;

      @media (max-width: $screen-md-min) {
        display: block;
      }
    }
  }

  nav {
    border-right: 1px solid #000000;
    padding-left: $page-indent / 2;

    > .menu {
      padding-bottom: $page-indent * 2;

      ul {
        li {
          border-bottom: 1px solid transparent;
          margin: 0.5em 0;

          &.active, &:hover:not(.active) {
            border-bottom-color: #000000;
          }

          &.active {
            font-size: $font-size-h4;
            font-weight: bold;
          }

          > a {
            color: $body-color;
            display: block;
            text-decoration: none;
          }
        }
      }
    }

    @media (max-width: $screen-md-min) {
      background: #E6E6E6;
      border-right: none;
      padding: 0 ($page-indent / 2);

      > .menu:not(.toggled) {
        display: none;
      }

      > .menu {
        padding-bottom: 0;
      }
    }
  }

  .nav-toggle {
    border-radius: 5px;
    color: $color-hilite;
    cursor: pointer;
    display: none;
    padding: 0.25em;

    &:hover {
      color: $color-primary;
    }

    @media (max-width: $screen-md-min) {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .page-content {
    padding: ($page-indent / 2) $page-indent;

    @media (max-width: $screen-md-min) {
      padding: 0;
    }
  }
}

.spinner {
  .ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: $color-primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .ellipsis div:nth-child(1) {
    left: 6px;
    animation: ellipsis1 0.6s infinite;
  }

  .ellipsis div:nth-child(2) {
    left: 6px;
    animation: ellipsis2 0.6s infinite;
  }

  .ellipsis div:nth-child(3) {
    left: 26px;
    animation: ellipsis2 0.6s infinite;
  }

  .ellipsis div:nth-child(4) {
    left: 45px;
    animation: ellipsis3 0.6s infinite;
  }

  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

.bordered-image {
  border: 1px solid #000000;
  @include box-shadow(4px 8px 15px rgba(0, 0, 0, .25));
}

.page-highlight-image {
  float: right;
  margin: 1rem;
  max-width: 380px;
}

@import 'styles/pages/home';
@import 'styles/pages/clubinfo';
@import 'styles/pages/directory';
@import 'styles/pages/bylaws';
@import 'styles/pages/qanda';
