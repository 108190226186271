@mixin box-shadow($arguments) {
  -webkit-box-shadow: $arguments;
  -moz-box-shadow: $arguments;
  box-shadow: $arguments;
}

@mixin gradient($color: #F5F5F5, $start: #EEEEEE, $stop: #FFFFFF) {
  background: $color;
  background: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0, $start),
      color-stop(1, $stop));
  background: -ms-linear-gradient(bottom,
      $start,
      $stop);
  background: -moz-linear-gradient(center bottom,
      $start 0%,
      $stop 100%);
  background: -o-linear-gradient($stop,
      $start);
}

@mixin gradient-vertical($startColor, $endColor) {
  background-color: mix($startColor, $endColor, 60%); // Base solid colour fallback
  background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
  background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
  background-image: linear-gradient(top, $startColor, $endColor); // The standard
  background-repeat: repeat-x;
}

@mixin transition($duration:0.2s, $ease:ease-out) {
  -webkit-transition: all $duration $ease;
  -moz-transition: all $duration $ease;
  -o-transition: all $duration $ease;
  transition: all $duration $ease;
}
