.info {
  .dinner-dates-container {
    display: flex;

    > table {
      margin-right: 1rem;

      &.previous-year {
        opacity: .5;
      }
    }
  }
}
